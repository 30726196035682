jQuery(window).on("load", function() {

    // Foreground images slider
    if (!!jQuery('.js-slick--foreground').length) {
        // Full width slick sliders
        jQuery('.js-slick--foreground').slick({
            adaptiveHeight: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true
        });
    }

});
